
.lens-shell-view {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0px;
}

.lens-shell-view > ui-view,
.lens-shell-view react-ui-view-adapter {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0px;
}

.embedded-kibana {
    // remove space left for old nav-bar
    #my-team-page,
    #discover-app-container,
    .dashboard-container,
    .job-app-container,
    .manage-app-container {
        padding-top: 0px;
    }

    // dashboard navbar custom placement
    .dashboard-container > navbar {
        position: relative;
        top      : 0px;
        flex-grow: 0;
    }

    .manage-app-container .manage-nav-container,
    .manage-app-container .settings-view-nav {
        display: none;
    }
}


// !!! *** Only add fixes for Lens Classic CSS issues (that affect React) below this line *** !!!

// Fix for close buttons disappearing on focus
button:hover, button:focus, button.focus {
    color: inherit;
}

.panel-body {
    padding: 0;
}

// Hides Elixir Default User Icon/Actions
.elx-user-actions {
    display: none;
}

.ms-Breadcrumb-list a {
    pointer-events: none;
}